<template>
    <div class="mainbox">
        <div style="font-size: 26px;color: #444444;font-weight: bold;margin-bottom: 23px;text-align: center;">校园新闻</div>

        <div v-if="zdItem" class="new_top">
            <div class="new_box">
                <div class="new_detail">
                    <div class="new_detail_img">
                        <img :src="zdItem.newCover" alt="">
                    </div>
                    <div class="new_detail_right">
                        <div class="new_zd">
                            <img src="~@/assets/img/TechnicalCollege/15.png" alt="">
                        </div>
                        <div class="n_til elp">{{ zdItem.newTitle }}</div>
                        <div class="line"></div>
                        <div v-html="ImgSizeLimitclear(zdItem.newDetail)" class="new_dec moreEllipsis">

                        </div>
                        <div @click="goNext(`/gxGathers/school/decsDetail?newid=${zdItem.id}&id=${id}`)"
                            class="new_btn">
                            <img src="~@/assets/img/TechnicalCollege/13.png" alt="">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="new_list">
            <div @click="goNext(`/gxGathers/school/decsDetail?newid=${item.id}&id=${id}`)"
                v-for="(item, index) in qusetionList" :key="index" class="new_list_item">
                <div class="item_left">
                    <img :src="item.newCover" alt="">
                </div>
                <div class="item_right">
                    <div class="item_til  elp">{{ item.newTitle }}</div>
                    <div v-html="ImgSizeLimitclear(item.newDetail)" class="item_dec twoEllipsis"></div>
                    <div class="item_time">{{ item.updateTime }}</div>
                </div>

            </div>
            <el-empty v-if="!qusetionList || qusetionList.length == 0" description="暂无数据"></el-empty>

            <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                class="pagination partner-pagination" :current-page="search.pageNum" :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]" />

        </div>
    </div>
</template>


<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            search: {
                pageSize: 10,
                pageNum: 1,
                projectTypeId: '',
                schoolId: ''
            },
            total: 0,
            qusetionList: [],
            zdItem: {}
        };
    },
    created() {
        this.search.schoolId = this.$route.query.id;
        this.search.projectTypeId = localStorage.getItem('projectTypeId');
        this.getContentNewPcList()
    },
    mounted() {

    },
    methods: {
        getContentNewPcList() {
            know.getContentNewPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                if (this.qusetionList && this.qusetionList.length > 0) {
                    if (this.qusetionList[0].sort == 1) {
                        this.zdItem = this.qusetionList[0]
                        this.qusetionList.shift()
                    } else {
                        this.zdItem = ''
                    }
                } else {
                    this.zdItem = ''
                }
                this.total = res.total
            });
        },
        handleSizeChange(val) {
            this.search.pageNum = 1
            this.search.pageSize = val
            this.getContentNewPcList()
        },
        handleCurrentChange(val) {
            this.search.pageNum = val
            this.getContentNewPcList()
        },
    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    padding-bottom: 10px;

    .new_top {
        width: 1200px;
        height: 390px;
        background: #EFBF9A;
        border-radius: 6px 6px 6px 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        .new_box {
            width: 1165px;
            height: 349px;
            background: linear-gradient(180deg, #ECE9E7 0%, rgba(254, 254, 254, 0.61) 100%);
            box-shadow: 0px 2px 6px 1px rgba(8, 54, 143, 0.07);
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;

            .new_detail {
                width: 1100px;
                display: flex;

                .new_detail_img {
                    width: 400px;
                    height: 300px;
                    background: #D8D8D8;
                    border-radius: 4px 4px 4px 4px;

                    img {
                        border-radius: 4px 4px 4px 4px;

                    }
                }

                .new_detail_right {
                    padding-left: 30px;
                    position: relative;
                    padding-top: 20px;


                    .n_til {
                        width: 600px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                        font-weight: normal;
                        font-size: 18px;
                        color: #333333;
                        font-weight: bold;
                    }

                    .line {
                        width: 40px;
                        height: 4px;
                        background: #1061FF;
                        border-radius: 3px 3px 3px 3px;
                        margin-top: 20px;
                    }

                    .new_dec {
                        width: 663px;
                        margin-top: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #555555;
                        line-height: 29px;
                    }

                    .new_btn {
                        width: 77px;
                        margin-top: 20px;
                        cursor: pointer;
                    }

                    .new_zd {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 43px;
                    }
                }
            }
        }
    }

    .new_list {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        margin-top: 20px;
        padding-bottom: 20px;

        .new_list_item {
            width: 1160px;
            padding: 20px 0;
            margin: 0 auto;
            display: flex;
            border-bottom: 1px solid #E5E5E5;
            cursor: pointer;

            .item_left {
                width: 195px;
                height: 142px;
                border-radius: 4px 4px 4px 4px;
                flex-shrink: 0;
                transition: 1.5s;

                img {
                    border-radius: 4px 4px 4px 4px;

                }

                &:hover {
                    transform: scale(1.2);
                }
            }

            .item_right {
                padding-left: 17px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .item_til {
                    width: 600px;
                    font-family: PingFangSC-Semibold, PingFangSC-Semibold;
                    font-weight: normal;
                    font-size: 18px;
                    color: #333333;
                }

                .item_dec {
                    width: 900px;
                    font-family: PingFangSC-Regular, PingFangSC-Regular;
                    font-weight: normal;
                    font-size: 14px;
                    color: #666666;
                    line-height: 24px;
                }

                .item_time {

                    font-family: PingFangSC-Regular, PingFangSC-Regular;
                    font-weight: normal;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }


}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>