import { render, staticRenderFns } from "./decs.vue?vue&type=template&id=93b912aa&scoped=true&"
import script from "./decs.vue?vue&type=script&lang=js&"
export * from "./decs.vue?vue&type=script&lang=js&"
import style0 from "./decs.vue?vue&type=style&index=0&id=93b912aa&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b912aa",
  null
  
)

export default component.exports